import {
  Card,
  CardActionArea,
  CardContent,
  CircularProgress, Container, Grid,
  Hidden, makeStyles,
  Paper, Typography,
  useMediaQuery
} from '@material-ui/core'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import Firebase from '../components/Firebase'
import Layout from '../components/layout'
import NewHeader from '../components/NewHeader'
import SEO from '../components/seo'
import Album from '../images/album.jpg'

const useStyles = makeStyles(theme => ({
  titleFont: {
    fontFamily: 'Volkhov',
    fontSize: 44,
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
  },
  marginMainTop: {
    marginTop: -150,
    marginBottom: 84,
  },
  marginIPADTop: {
    marginTop: -200,
    marginBottom: 84,
  },
  mt24: {
    marginTop: 24,
  },
  white: {
    color: '#fff',
  },
  paddingAll24: {
    padding: 18,
  },
  pall: {
    padding: 24,
  },
}))
const Photos = props => {
  const [aid] = useQueryParam('aid', StringParam)
  const [rows, setRows] = useState([])
  const matches = useMediaQuery('(max-width:1024px)')
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  useEffect(() => {
    setLoading(true)
    Firebase.firestore()
      .collection('albums')
      .doc(aid)
      .collection('photos')
      .get()
      .then(function(resp) {
        setLoading(false)
        setRows([])
        resp.forEach(function(doc) {
          setRows(old => [...old, doc])
        })
      })
      .catch(function(error) {
        console.log(error.message)
      })
  }, [aid])
  return (
    <div>
      <Layout>
        <SEO title="Gallery" />
        <div>
          <NewHeader title="About us" image={Album} />
          {matches ? (
            <div className={classes.marginIPADTop}>
              <Container>
                <Typography className={clsx(classes.titleFont, classes.white)}>
                  Gallery
                </Typography>
                <Grid container spacing={2} className={classes.mt24}>
                  <Hidden xsDown mdDown>
                    <Grid item md={12} xs={12} sm={12}>
                      <Paper square>
                        <div
                          style={{ backgroundColor: '#3c5eab', padding: 6 }}
                        ></div>
                        <div>
                          {loading ? (
                            <div align="center" className={classes.pall}>
                              <CircularProgress />
                              <br />
                              <Typography variant="h5">
                                Loading Albums
                              </Typography>
                            </div>
                          ) : (
                            <Grid container spacing={2}>
                              {rows.map((v, k) => (
                                <Grid item md={3} key={k}>
                                  <Card>
                                    <CardActionArea>
                                      <img
                                        src={v.data().downloadUrl}
                                        alt={v.data().title}
                                        height="250"
                                      />
                                      <CardContent>
                                        <Typography component="Lizard">
                                          {v.data().title}
                                        </Typography>
                                      </CardContent>
                                    </CardActionArea>
                                  </Card>
                                </Grid>
                              ))}
                            </Grid>
                          )}
                        </div>
                      </Paper>
                    </Grid>
                  </Hidden>
                  <Grid item md={12} xs={12} sm={12}>
                    {/* <Paper square> */}
                    {/* <div
                      style={{ backgroundColor: '#3c5eab', padding: 6 }}
                    ></div> */}
                    <div>
                      {loading ? (
                        <div align="center" className={classes.pall}>
                          <CircularProgress />
                          <br />
                          <Typography variant="h5">Loading Albums</Typography>
                        </div>
                      ) : (
                        <Grid container spacing={2}>
                          {rows.map((v, k) => (
                            <Grid item md={3} key={k} xs={12}>
                              <Card>
                                <CardActionArea>
                                  <img
                                    src={v.data().downloadUrl}
                                    alt={v.data().title}
                                    height="250"
                                  />
                                  <CardContent>
                                    <Typography component="Lizard">
                                      {v.data().title}
                                    </Typography>
                                  </CardContent>
                                </CardActionArea>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </div>
                    {/* </Paper> */}
                  </Grid>
                </Grid>
              </Container>
            </div>
          ) : (
            ''
          )}
          <div className={classes.marginMainTop}>
            <Container>
              <Typography className={clsx(classes.titleFont, classes.white)}>
                Gallery
              </Typography>
              <Grid container spacing={2} className={classes.mt24}>
                {matches ? (
                  ''
                ) : (
                  <Grid item md={12} xs={12} sm={12}>
                    {/* <div
                      style={{ backgroundColor: '#3c5eab', padding: 6 }}
                    ></div> */}

                    <div>
                      {loading ? (
                        <div align="center" className={classes.pall}>
                          <CircularProgress />
                          <br />
                          <Typography variant="h5">Loading Albums</Typography>
                        </div>
                      ) : (
                        <Grid container spacing={2}>
                          {rows.map((v, k) => (
                            <Grid item md={3} key={k}>
                              <Card>
                                <CardActionArea>
                                  <img
                                    src={v.data().downloadUrl}
                                    alt={v.data().title}
                                    height="250"
                                  />
                                  <CardContent>
                                    <Typography component="Lizard">
                                      {v.data().title}
                                    </Typography>
                                  </CardContent>
                                </CardActionArea>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </div>
                  </Grid>
                )}
              </Grid>
            </Container>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default Photos
